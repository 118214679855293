import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Section from './components/Section/Section';



function App() {
  return (
    <div className="App">
       <Header />
       <Section />
       <Footer />
    </div>
  );
}

export default App;

import './Footer.css'

const Footer = () =>{
    return(
        <div className="footer">
            <div className="container">
                <div className="footer-page">
                    <img src="https://static.tildacdn.info/tild3461-3230-4230-a138-316130373734/image_987.png" alt="" className="footer-img" />
                </div>
                <p className='footer-text'>Bizni boshqa tarmoqlarda ham kuzating, yangiliklardan qolib ketmang!</p>

                <div className="footer-list">
                    <div className='footer-title'><a href="https://www.instagram.com/spm_school/" className="footer-link">Instagram</a></div>
                    <div className='footer-title'><a href="https://t.me/spm_bio" className="footer-link">Telegram</a></div>
                    <div className='footer-title'><a href="https://m.youtube.com/channel/UC7oI3eu-GTXQ1obLchu5upw" className="footer-link">You Tube</a></div>
                    <div className='footer-title'><a href="https://www.instagram.com/stories/highlights/18163896046025370/" className="footer-link">Natijalar</a></div>
                    <div className='footer-title'><a href="Tel:+998975761010" className="footer-link">Telefon</a></div>
                    <div className='footer-title'><a href="https://yandex.uz/maps/org/201635684575/?ll=66.956199%2C39.660584&z=16" className="footer-link">Joylashuvimiz</a></div>
                    <div className='footer-title'><a href="https://www.instagram.com/stories/highlights/17881034911807960/" 
                    className="footer-link">Yangiliklar</a></div>
                    <div className='footer-title'><a href="https://www.instagram.com/stories/highlights/17927478160197193/" className="footer-link">Ijtimoiy hayot</a></div>
                    <div className='footer-title'><a href="https://www.instagram.com/stories/highlights/17922499825221653/" className="footer-link">Xonalar</a></div>
                    <div className='footer-title'><a href="https://www.tiktok.com/@spm.house?_d=secCgYIASAHKAESPgo84F4k5ncTNq96a%2B6j0yFzFQkkAL7xi3mjGpXU3%2FWnIKak6LXrXZzuFjgYFaUJ0Fvhy4sUUEWswmjfmD1NGgA%3D&_r=1&language=ru&sec_uid=MS4wLjABAAAA73dawm7u7kkOIPJU" className="footer-link">TikTok</a></div>
                    <div className='footer-title'><a href="https://www.facebook.com/spm.bio" className="footer-link">Facebook</a></div>
                </div>
                <p className='footer-texts'>© All Rights Reserved. ABBA marketing</p>
            </div>
        </div>
    )
}
export default Footer